<template>
  <div>
    <h3>Thông tin tác vụ</h3>
    <div class="card" style="background-color: #F6F6F6">
      <div class=" p-4 pb-6  row  ">
        <div class="col-6 mb-3">
          <label>Mã tác vụ</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " :value="informationTask.taskCode"
                 disabled>
        </div>
        <div class="col-6 mb-3">
          <label>Tên tác vụ</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled
                 :value="informationTask.taskName">
        </div>
        <div class="col-6 mb-3">
          <label>Loại tác vụ</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled
                 :value="informationTask.taskType">
        </div>
        <div class="col-6 mb-3">
          <label>Hình thức test</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled
                 :value="informationTask.testForm">
        </div>
        <!--        <div class="col-6 mb-3">-->
        <!--          <label>Loại đề test</label>-->
        <!--          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled :value="informationTask?.typeOfTestQuestion">-->
        <!--        </div>-->
        <div class="col-6 mb-3">
          <label>Ngày làm</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled
                 :value="informationTask?.workday">
        </div>
        <div class="col-6 mb-3">
          <label>Thời gian bắt đầu</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled
                 :value="informationTask?.startTime">
        </div>
        <div class="col-6 mb-3">
          <label>Thời gian kết thúc</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled
                 :value="informationTask?.endTime">
        </div>
        <div class="col-6 mb-3">
          <label>Trung tâm</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled
                 :value="informationTask?.center">
        </div>
        <div class="col-6 mb-3">
          <label>Cơ sở</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled
                 :value="informationTask?.branch">
        </div>
        <div class="col-6 mb-3">
          <label>Họ và tên khách hàng</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled
                 :value="informationTask?.customerName">
        </div>
        <div class="col-6 mb-3">
          <label>Số điện thoại khách hàng</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled
                 :value="informationTask?.customerPhone">
        </div>
        <div class="col-6 mb-3">
          <label>Người đặt lịch</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled
                 :value="informationTask?.informationCounselors">
        </div>
        <div v-if="informationTask.testForm !== 'Online'" class="col-6 mb-3">
          <label>Thời gian bắt đầu vào test</label>
          <input class="bg-white  d-block w-100  rounded-sm px-3 py-1 form-control " disabled
                 :value="informationTask?.come_check_date | formatDateTimeAsia">
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "TaskInformation",
  props: {
    informationTask: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped>
input:focus {
  outline: none;
}
</style>